import React from 'react'
import withRouter from "../services/withRouter";
import TopNavBar from '../UIComponents/TopNavBar/TopNavBar'
import {ENTITIES} from "../services/dataManagement/entities";
import EntityCard from "../UIComponents/EntityCard/EntityCard";
import LoadSpinner from "../UIComponents/LoadSpinner/LoadSpinner";
import {E_ENTITIES} from "../services/dataManagement/entityDefinitions";
import { IRole } from "../services/interfaces";

export interface IEntityBrowserPageProps {
	location: any,
	navigate: any,
	params: any,
	searchParams: any,
	setSearchParams: any,
	entity: E_ENTITIES,
	userRole: IRole
}
export interface IEntityBrowserPageState {
	loading: boolean,
	id: number | null,
	pageTitle: string | JSX.Element | null,
}

class EntityCardPage extends React.Component <IEntityBrowserPageProps, IEntityBrowserPageState> {
	constructor(props: IEntityBrowserPageProps) {
		super(props);
		const entity = ENTITIES[this.props.entity];
		const idProp = this.props.searchParams.get("id");
		const id = (idProp === null || idProp === undefined) ? null : idProp;
		this.state = {
			loading: id === null,
			id: id,
			pageTitle: (id === null) ? entity.browsingPage.newItemLabel : null
		}
	}

	async componentDidMount(){
		const id = this.props.searchParams.get("id");
		this.setState({
			id: (id || (id === 0)) ? id : null,
			loading: true,
		})
	}

	render() {
		return (
			<div className={"contacts-browser-wrap"}>
				<TopNavBar userRole={this.props.userRole}>
					<div className="title-wrap">
						{this.state.pageTitle ? this.state.pageTitle : <LoadSpinner radius={2} visibility={true}/>}
					</div>
				</TopNavBar>
				<div className="content-wrap">
					<EntityCard
						id={this.state.id}
						entity={this.props.entity}
						onLoad={(data:any)=> {
							this.setPageTitleByData(data)
							const entity = ENTITIES[this.props.entity];
							if (entity.processData) entity.processData(data, entity)
						}}
						navigate={this.props.navigate}
						userRole={this.props.userRole}
					/>
				</div>
			</div>
		)
	}

	private setPageTitleByData(data:any) {
		const entity = ENTITIES[this.props.entity];
		this.setState({pageTitle: entity.cardPage.titleFromData ? entity.cardPage.titleFromData(data) : entity.cardPage.title})
	}
}
export default (withRouter(EntityCardPage));