import React from 'react';
import server from '../services/server';
import cookie from 'js-cookie';
import IdentificationField from './login/IdentificationField';
import LogoutReminder from './login/LogoutReminder';
import './login/Login.scss';
import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignInAlt} from '@fortawesome/free-solid-svg-icons'
import queryString from "query-string";
import PubSub from "pubsub-js";
import events from "../lib/events";
import withRouter from "../services/withRouter"

library.add(faSignInAlt);

export default (withRouter(class Login extends React.Component {
constructor(props) {
	super(props);
	this.state = {
		redirect: queryString.parse(props.location.search, { ignoreQueryPrefix: true }).redirect,
		phone: "",
		email: "",
		code: "",
		codeSent: false,
		displayLogoutReminder: !cookie.get('personalComputer')
	};
}

componentDidMount() {
	if(cookie.get('token')){
		server.get("login/permissions", {ignoreErrors: true}).then((res)=>{
			if(res.user_access && ! res.error){
				this.props.saveUserRole(res.user_role)
				if(this.state.redirect){
					try{
						this.props.navigate(decodeURIComponent(this.state.redirect));
					}
					catch(e){
						console.log(e)
					}
				}
				this.props.navigate('/Contacts');
			}
		})
	}
}

closeLogoutReminder = function(isPersonalComputer){
	this.setState({displayLogoutReminder: false}, ()=>{
		if(isPersonalComputer) {
			cookie.set('personalComputer', true);
		}
	})
}.bind(this);

validateEmail = function(email){
	//checks for example@example.example, such that [example] doesn't contain a '@'
	return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};


identifyByEmail(email){
	server.post('login/identify/', {'email':email.toLowerCase()})
	.then(() => {
		this.setState({codeSent: true, identificationMethod: "Email", email: email});
	});
}

setLoginCode = function (event){
	this.setState({code: event.target.value})
}.bind(this);

handleCodeKeyDown = function(event){
	if(event.key === 'Enter'){
		this.verifyLoginCode();
	}
}.bind(this);

resendCode = function(){
	this.setState({codeSent: false});
}.bind(this);

verifyLoginCode()
{
	const code = this.state.code;
	const data ={'email':this.state.email, 'code':code};
	server.post("login/login", data)
	.then(json => {
		if(json.err)
		{
			switch(json.err){
				case "incorrect credentials":
					PubSub.publish(events.alert, {
						content: "the email or code you've entered are incorrect - try again.",
						flush: false,
						opaque: false,
						onClose: () => {},
						resolutionOptions: [
							{
								label: "ok",
								onClick: () => {PubSub.publish(events.clearAlert, {})},
							}
						]
					});
					break;
				case "missing identification":
					PubSub.publish(events.alert, {
						content: "please provide an email address",
						flush: false,
						opaque: false,
						onClose: () => {},
						resolutionOptions: [
							{
								label: "ok",
								onClick: () => {PubSub.publish(events.clearAlert, {})},
							}
						]
					});
					break;
				case "locked":
					PubSub.publish(events.alert, {
						content: "your account has been locked. " +
							"contact the system administrators to unlock your account.",
						flush: false,
						opaque: false,
						onClose: () => {},
						resolutionOptions: [
							{
								label: "ok",
								onClick: () => {PubSub.publish(events.clearAlert, {})},
							}
						]
					});
					break;
				case "tooManyLogins":
					PubSub.publish(events.alert, {
						content: "you have made too many incorrect login attempts, and your user has been locked out of the system. " +
							"contact the system administrators to unlock your account.",
						flush: false,
						opaque: false,
						onClose: () => {},
						resolutionOptions: [
							{
								label: "ok",
								onClick: () => {PubSub.publish(events.clearAlert, {})},
							}
						]
					});
					break;
				default:
					PubSub.publish(events.alert, {
						content: "an unknown error has occurred. Try again later.",
						flush: false,
						opaque: false,
						onClose: () => {},
						resolutionOptions: [
							{
								label: "ok",
								onClick: () => {PubSub.publish(events.clearAlert, {})},
							}
						]
					});
					break;
			}
		}
		if(json.token)
		{
			cookie.set('token', json.token, {expires: 30});
			cookie.set('permissions', JSON.stringify(json.permissions), {expires: 30});
			this.props.saveUserRole(json.permissions.role)
			if(this.state.redirect){
				try{
					this.props.navigate(decodeURIComponent(this.state.redirect));
					return;
				}
				catch(e){
					console.log(e)
				}
			}
			this.props.navigate('/Contacts');
		}
	});
}

render() {
	/**Stage 1 - Verification Method Selection**/
	//TODO - the arabic here isn't up to date
	const identification =
		<div>
			<div className='identification-input-title'>
				<div>
					مصادقة عبر
				</div>
				<div>
					הזינו כתובת אימייל
				</div>
			</div>
			<IdentificationField
				dir="ltr" inputType="email" minLength="5" maxLength="100"
				placeholder="Email Address"
				validationFunction={this.validateEmail}
				identificationFunction={this.identifyByEmail.bind(this)}
			/>
		</div>;
	/**Stage 2 - Login Code Input**/
	const loginCode =
		<div>
			<br/>
			<div className='code-input-title'>
				{"הזינו את הקוד שנשלח אליכם במייל"}
			</div>
			<br/>
			<input className={"login-code"} value={this.state.code} onChange={(event) => {this.setLoginCode(event)}} onKeyDown={(event)=>{this.handleCodeKeyDown(event)}}/>
			<button type={"button"} className={"submit-code-button"} onClick={this.verifyLoginCode.bind(this)}>
				<FontAwesomeIcon icon="sign-in-alt"/>
			</button>
			<div className={"back-to-identification"} onClick={this.resendCode}>חזרה</div>
		</div>;
	return (
		<div className='page-wrap-login' dir="rtl">
			{
				this.state.displayLogoutReminder
					? <LogoutReminder onClose={this.closeLogoutReminder}/>
					: <div>
						{this.state.codeSent ? loginCode : identification}
				</div>
			}

		</div>
	)
}

}))

