import { IEntity } from "../entities";
import { Tables } from "../tables";
import { fieldType, IField } from "../fields";
import { IEntityTableCol } from "../tableColumns";
import { ICard, IState } from "../cardControls";
import { E_ENTITIES } from "../entityDefinitions";
import { FIELD_JUSTIFICATION } from "../fieldControls";


export enum ELandingPageFields {
    activity_id, title, description, image, imageRestrictionsMsg, fields, whatsappSendMessage, whatsappTemplate, emailSendMessage, emailTemplate, descriptionDefaultMsg,
    create_date
}

export const landingPageFields: { [key in ELandingPageFields]: IField } = {
    [ELandingPageFields.activity_id]: {
        field: "activity_id",
        defaultVal: "",
        type: fieldType.entityType,
        options: { entity: E_ENTITIES.activities },
        validate: [validateActivity],
        title: "פעילות"
    },
    [ELandingPageFields.title]: {
        field: "title",
        defaultVal: "",
        type: fieldType.textType,
        title: "כותרת"
    },
    [ELandingPageFields.description]: {
        field: "description",
        hide: false,
        dir: FIELD_JUSTIFICATION.RTL,
        validate: [validateDescription],
        defaultVal: '',
        type: fieldType.textareaType,
        title: "תיאור"
    },
    [ELandingPageFields.descriptionDefaultMsg]: {
        type: fieldType.NONE,
        field: 'descriptionDefaultMsg',
        defaultVal: 'בהשארת שדה התיאור ריק - יוצג בעמוד הנחיתה טקסט ברירת מחדל על עומדים ביחד. כדי לא להציג תיאור בכלל יש להזין רווח או ירידת שורה לשדה התיאור',
        title: ''
    },
    [ELandingPageFields.image]: {
        field: "image",
        defaultVal: false,
        type: fieldType.imageUploaderType,
        title: "תמונה",
        validate: [validateImageUploader]
    },
    [ELandingPageFields.imageRestrictionsMsg]: {
        type: fieldType.NONE,
        field: 'imageRestrictionsMsg',
        defaultVal: `שימו לב - יש להעלות תמונה בפורמט 16/9 במשקל של עד 1 מגהבייט. לדוגמה: תמונה בגודל 
        1920ₓ1080`,
        title: ''
    },
    [ELandingPageFields.fields]: {
        field: "fields",
        defaultVal: "",
        type: fieldType.dropdown,
        title: "שדות",
        options: [
            {
                value: "basic",
                text: "בסיסי"
            },
            {
                value: "students",
                text: "סטודנטים"
            },
            // {
            //     value: "",
            //     text: "none"
            // },
        ]
    },
    [ELandingPageFields.whatsappSendMessage]: {
        field: "whatsappSendMessage",
        defaultVal: false,
        type: fieldType.booleanType,
        validate: [validateWhatsAppCheckbox],
        title: "שליחת הודעת ווטסאפ אוטומטית"
    },
    [ELandingPageFields.whatsappTemplate]: {
        field: "whatsappTemplate",
        defaultVal: "",
        type: fieldType.textType,
        title: "הודעת ווצאפ"
    },
    [ELandingPageFields.emailSendMessage]: {
        field: "emailSendMessage",
        defaultVal: false,
        type: fieldType.booleanType,
        validate: [validateEmailCheckbox],
        title: "שליחת הודעת מייל אוטומטית"
    },
    [ELandingPageFields.emailTemplate]: {
        field: "emailTemplate",
        defaultVal: "",
        type: fieldType.textType,
        title: "הודעת אימייל"
    },
    [ELandingPageFields.create_date]: {
        field: "create_date",
        defaultVal: () => { return (new Date()).toISOString() },
        type: fieldType.dateType,
        title: "תאריך יצירה"
    },
}

async function validateImageUploader(file: any, entity?: IEntity) {
    const imageUploadField = entity?.fields.find((f: IField) => f.field === 'image')
    if (imageUploadField) imageUploadField.hide = !!file
    if (!file) {
        return { valid: true }
    }
    const fileSizeKB = file.size / 1000;
    let imageSize = await readImageSize(file)
    let ratio = imageSize.width / imageSize.height
    let valid = file.type && file.type.includes('image') && fileSizeKB <= 1000 && 1.7 < ratio && ratio < 1.8
    return { valid, err: valid ? undefined : "יש לבחור תמונה ביחס 16/9 בגודל של עד מגהבייט" }
}

function readImageSize(file: File): Promise<{ width: number, height: number }> {
    const reader = new FileReader();
    return new Promise((resolve, reject) => {
        reader.addEventListener(
            "load",
            () => {
                const image = new Image();
                image.addEventListener("load", () => {
                    resolve({
                        width: image.naturalWidth,
                        height: image.naturalHeight
                    })
                })
                if (typeof reader.result === 'string') image.src = reader.result;
                else reject()
            },
            false,
        );

        reader.readAsDataURL(file);
    })
}


function validateWhatsAppCheckbox(value: any, entity?: IEntity) {
    const whatsappTemplateField = entity?.fields.find((f: IField) => f.field === 'whatsappTemplate')
    if (whatsappTemplateField) whatsappTemplateField.hide = !value
    return { valid: true }
}

function validateEmailCheckbox(value: any, entity?: IEntity) {
    const emailTemplateField = entity?.fields.find((f: IField) => f.field === 'emailTemplate')
    if (emailTemplateField) emailTemplateField.hide = !value
    return { valid: true }
}

function validateDescription(value: any, entity?: IEntity) {
    if (entity) {
        const defaultDescriptionMsgField = entity.fields.find((f: IField) => f.field === 'descriptionDefaultMsg')
        if (defaultDescriptionMsgField) defaultDescriptionMsgField.hide = !!value
    }
    return { valid: true }
}

function validateActivity(activity: any) {
    const valid = !(activity === null || activity === undefined);
    return { valid: !!valid, err: valid ? undefined : "כל עמוד נחיתה צריך להיות מזוהה עם פעילות" }
}

export const LandingPageBrowserCols: IEntityTableCol[] = [
    {
        field: landingPageFields[ELandingPageFields.create_date] as IField,
        readonly: true,
        defaultWidth: 5,
    },
    {
        field: landingPageFields[ELandingPageFields.title] as IField,
        readonly: true,
        defaultWidth: 10,
    },
]

export const LandingPageCard: ICard = {
    sections: [
        {
            compact: true,
            hideOnNew: true,
            fields: [
                {
                    field: landingPageFields[ELandingPageFields.create_date],
                    readonly: true
                },
            ]
        },
        {
            fields: [
                { field: landingPageFields[ELandingPageFields.title] },
                { field: landingPageFields[ELandingPageFields.activity_id], readonly: true },
                { field: landingPageFields[ELandingPageFields.description] },
                { field: landingPageFields[ELandingPageFields.descriptionDefaultMsg] },
                { field: landingPageFields[ELandingPageFields.image] },
                { field: landingPageFields[ELandingPageFields.imageRestrictionsMsg] },
                { field: landingPageFields[ELandingPageFields.fields] },
                // { field: landingPageFields[ELandingPageFields.whatsappSendMessage] },
                // { field: landingPageFields[ELandingPageFields.whatsappTemplate] },
                // { field: landingPageFields[ELandingPageFields.emailSendMessage] },
                // { field: landingPageFields[ELandingPageFields.emailTemplate] },
            ]
        },
    ],
    // Slot are rendered according to their key, after the specified field in the specified section: 
    // [section index]_[field index]
    slots: {
        '1_5': (state: IState) => {
            switch (state.data.fields) {
                case 'basic':
                    return <div className="fields-indicator-wrapper">
                        <span className="title">דוגמה לשדות שנבחרו</span>
                        <div className="fields-indicator">
                            <div className="field">
                                <span>الاسم الشخصي שם פרטי *</span><span className="underline"></span>
                            </div>
                            <div className="field">
                                <span>اسم العائلة שם משפחה *</span><span className="underline"></span>
                            </div>
                            <div className="field">
                                <span>رقم الهاتف טלפון</span><span className="underline"></span>
                            </div>
                            <div className="field">
                                <span>البريد الإلكتروني כתובת דוא"ל</span><span className="underline"></span>
                            </div>
                            <div className="field">
                                <span>مكان الاقامة יישוב מגורים *</span><span className="underline"></span>
                            </div>
                        </div>
                    </div>
                case 'students':
                    return <div className="fields-indicator-wrapper">
                        <span className="title">דוגמה לשדות שנבחרו</span>
                        <div className="fields-indicator">
                            <div className="field">
                                <span>الاسم الشخصي שם פרטי *</span><span className="underline"></span>
                            </div>
                            <div className="field">
                                <span>اسم العائلة שם משפחה *</span><span className="underline"></span>
                            </div>
                            <div className="field">
                                <span>رقم الهاتف טלפון</span><span className="underline"></span>
                            </div>
                            <div className="field">
                                <span>البريد الإلكتروني כתובת דוא"ל</span><span className="underline"></span>
                            </div>
                            <div className="field">
                                <span>مكان الاقامة יישוב מגורים *</span><span className="underline"></span>
                            </div>
                            <div className="field">
                                <span>مؤسسة أكاديميّة מוסד אקדמאי</span><span className="underline"></span>
                            </div>
                            <div className="field">
                                <span>موضوع التعليم חוג לימודים</span><span className="underline"></span>
                            </div>
                        </div>
                    </div>
                default:
                    return <span>ERROR</span>
            }
        },
        '1_4': (state: IState, setImageValue: (v: any) => void) => {
            function deleteImage() {
                setImageValue(false)
            }
            const image = state.data.image
            if (!image) return <div></div>;
            const source = image instanceof File ? URL.createObjectURL(image) : `../landing_pages_images/${state.data.id}`
            return <div className="image-preview-wrapper">
                <button onClick={deleteImage}>✖</button>
                <img src={source}></img>
            </div>
        },
    },
    relatedEntities: [
    ]
}

export const LandingPageEntity: IEntity = {
    browsingPage: {
        suffix: "landing-pages",
        title: "עמודי נחיתה",
        newItemLabel: "עמוד נחיתה חדש"
    },
    cardPage: {
        suffix: "landing-page",
        title: "עמוד נחיתה",
    },
    endpoint: "landingPages",
    fields: Object.values(landingPageFields),
    table: Tables.DB_TABLE_LANDINGPAGE,
    tableColumns: LandingPageBrowserCols,
    cardLayout: LandingPageCard,
    processData: function (data: any, entity: IEntity) {
        const defaultDescriptionMsgField = entity.fields.find((f: IField) => f.field === 'descriptionDefaultMsg')
        if (defaultDescriptionMsgField) defaultDescriptionMsgField.hide = !!data.description

        const imageUploadField = entity.fields.find((f: IField) => f.field === 'image')
        if (imageUploadField) imageUploadField.hide = !!data.image

        const whatsappTemplateField = entity?.fields.find((f: IField) => f.field === 'whatsappTemplate')
        if (whatsappTemplateField) whatsappTemplateField.hide = !data.whatsappSendMessage

        const emailTemplateField = entity?.fields.find((f: IField) => f.field === 'emailTemplate')
        if (emailTemplateField) emailTemplateField.hide = !data.emailSendMessage
    }
}