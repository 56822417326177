import React from 'react'
import './JSONQueryEditor.scss';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {ISchemaField, ISchemaFieldType} from "../JSONSchemaEditor/JSONSchemaEditor";
import DatePicker from 'react-date-picker';
import CitySearch from "../CitySearch/CitySearch";
import {ColumnTypeDict, EDBColumnTypes} from "../QueryBuilder/DBColumnTypes";
import LoadSpinner from "../LoadSpinner/LoadSpinner";

interface IProps {
    data: any,
    schema: ISchemaField | null,
    onFilterChange: (filter: JSONFilter | string | null) => void,
    onChange: (data: any) => void,
    onSubmit: () => void,
    inArray ?: boolean,
    arrayIndex ?: number,
    filter: JSONFilter | string | null,
}


interface IState {}

interface JSONFilter {[key: string]: (JSONFilter | string)}


export default class JSONQueryEditor extends React.Component <IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: props.data,
            schema: props.schema,
            onFilterChange: props.onFilterChange,
            onChange: props.onChange,
            onSubmit: props.onSubmit,
            inArray: props.inArray,
            filter: props.filter
        };
    }

    static getDerivedStateFromProps(nextProps: IProps, state: IState) {
        return {
            data: nextProps.data,
            schema: nextProps.schema,
            onChange: nextProps.onChange,
            onSubmit: nextProps.onSubmit,
            inArray: nextProps.inArray,
            filter: nextProps.filter
        };
    }

    onChange = (data:any) => {
        this.props.onChange(data);
    };

    onFilterChange = (data:any) => {
        this.props.onFilterChange(data);
    }

    validate = () => {
    };

    onPropertyEdit = (field:string, value: any) => {
        const data:any =  {};
        data[field] = value;
        this.onChange(data);
    };

    onPropertyFilterEdit = (field:string, subFilter: JSONFilter) => {
        if((typeof this.props.filter === "string")){
            return;
        }
        const filter:any =  {};
        filter[field] = subFilter;
        this.onFilterChange(filter);
    };

    onArrayEntryEdit = (index:number, value: any) => {
        const data = (this.props.data && Array.isArray(this.props.data)) ? this.props.data.slice() : [];
        data[index] = value;
        this.onChange(data);
    };

    addArrayEntry = () => {
        const data = (this.props.data && Array.isArray(this.props.data)) ? this.props.data.slice() : [];
        data.push(null);
        this.onChange(data);
    };
    deleteArrayEntry = (entryIndex:number) => {
        const data = (this.props.data && Array.isArray(this.props.data)) ? this.props.data.slice() : [];
        data.splice(entryIndex, 1);
        this.onChange(data);
    };

    onSubmit = () => {
        return this.props.onSubmit ? this.props.onSubmit() : null;
    }

    getInputMechanism = (schema:ISchemaField) =>{
        const data = this.props.data;
        const filter = this.props.filter ? this.props.filter : null;
        let colType = null;
        switch (schema.fieldType) {
            case ISchemaFieldType[ISchemaFieldType.objectField]:
                const filterPaths = data ? Object.keys(data) : [];
                const selectedFilter = filterPaths.length ? filterPaths[0] : null;
                const selectedSubSchema = selectedFilter ? schema.properties.slice().filter(f=> {
                    return f.fieldKey === selectedFilter
                })[0] : null;
                return <React.Fragment>
                    <span>תתי-שדות:</span>
                    <select value={selectedFilter || ""} onChange={(e)=>{
                        const data:any = {}
                        data[e.target.value] = null
                        this.onChange(data);
                        this.onFilterChange(data);
                    }}>
                        <option value=""></option>
                        {
                            schema.properties.slice().map((p, i) => {
                                return <option key={"property_"+i} value={p.fieldKey}>{p.fieldTitle}</option>
                            })
                        }
                    </select>
                    {
                        selectedFilter && selectedFilter.length ?
                            <JSONQueryEditor
                                data={data ? data[selectedFilter] : null}
                                schema={selectedSubSchema}
                                  onChange={(subData: any) => {
                                      const filterPaths = data ? Object.keys(data) : [];
                                      const selectedFilter = filterPaths.length ? filterPaths[0] : null;
                                      if(selectedFilter) {
                                          this.onPropertyEdit(selectedFilter, subData);
                                      }
                                  }}
                                  onSubmit={() => {
                                      this.onSubmit()
                                  }}
                                  onFilterChange={(subData: any) => {
                                      const filterPaths = data ? Object.keys(data) : [];
                                      const selectedFilter = filterPaths.length ? filterPaths[0] : null;
                                      if(selectedFilter) {
                                          this.onPropertyFilterEdit(selectedFilter, subData);
                                      }
                                  }}
                                  filter={(filter && typeof filter === "object") && filter[selectedFilter] ? filter[selectedFilter] : null}
                        /> : null
                    }
                </React.Fragment>
            ;
            case ISchemaFieldType[ISchemaFieldType.stringField]:
                colType = ColumnTypeDict[EDBColumnTypes.STRING_COL];
                break;
            case ISchemaFieldType[ISchemaFieldType.dateField]:
                colType = ColumnTypeDict[EDBColumnTypes.DATE_COL];
                break;
            case ISchemaFieldType[ISchemaFieldType.numberField]:
                colType = ColumnTypeDict[EDBColumnTypes.NUMBER_COL];
                break;
            case ISchemaFieldType[ISchemaFieldType.cityField]:
                colType = ColumnTypeDict[EDBColumnTypes.CITY_COL];
                break;
            case ISchemaFieldType[ISchemaFieldType.booleanField]:
                colType = ColumnTypeDict[EDBColumnTypes.BOOLEAN_COL]
                if(data === null) {
                    this.onFilterChange("b_is")
                    this.onChange("false")
                    this.onSubmit()
                }
                break;
            default: break;
        }
        if(!colType){
            return null;
        }
        if(!(typeof filter === "string") && filter !== null){
            return null;
        }
        const filterParams = colType && filter ? colType.availableFilters[filter] : null;

        const onlyOneFilter = colType && colType.availableFilters && Object.keys(colType.availableFilters).length === 1
        
        return <React.Fragment>
                    {
                        onlyOneFilter?
                        <div className='filter-one-option'>{Object.values(colType.availableFilters)[0].label}</div>
                        :
                        <select
                            className={"condition-filter-selector"}
                            onChange={(e)=>{this.onFilterChange(e.target.value)}}
                            value={filter ? filter : ""}>
                            <option value={""}/>
                            {
                                Object.values(colType.availableFilters).map((f, i)=>{
                                    return <option key={"filter-selector-"+f.key} value={f.key}>{f.label}</option>
                                })
                            }
                        </select>
                    }
                    <div>
                        {
                            filterParams ?
                                filterParams.inputTemplate(this.props.onChange, this.onSubmit, this.props.data, {})
                                : null
                        }
                    </div>
                </React.Fragment>;
    };

    render() {
        const schema = this.props.schema;
        if(!schema){
            return <div><LoadSpinner visibility={true} radius={2}/></div>
        }
        const inArray = this.props.inArray;
        const isArray = schema.isArray && !inArray;
        const data = isArray ? (this.props.data && Array.isArray(this.props.data) ? this.props.data : []).slice() : this.props.data;
        return (
            <div className={"json-query-editor"}>
                {
                    schema.isRoot ? null : <React.Fragment>
                        <span>
                            <span>{isArray ? "רשימה של: " : ""}</span>
                            <span>{schema.fieldTitle} </span>
                            <span>({schema.fieldKey + (inArray ? "["+this.props.arrayIndex+"]" : "")}) </span>
                        </span>
                        <br/>
                    </React.Fragment>
                }
                {
                    isArray ? <React.Fragment>{
                            (data ? data : []).map((entry: any, i: number) => {
                                return <span className={"array_entry_wrap"}
                                             key={(schema.fieldKey + "_array_entry_" + i +"_at_length_"+(data ? data : []).length)}>
                                    <button
                                        className={"delete-array-entry-button"}
                                        onClick={()=>{this.deleteArrayEntry(i);}}>
                                        <FontAwesomeIcon icon={faTimes}/>
                                    </button>
                                    {/* TODO: Array filtering */}
                                </span>
                            })
                        }
                            <button type={"button"} className={"add-array-entry-button"} onClick={()=>{this.addArrayEntry()}}>
                                <FontAwesomeIcon icon={faPlus}/>
                                <span>הוספת רשומה חדשה</span>
                            </button>
                    </React.Fragment>
                        : this.getInputMechanism(schema)
                }
            </div>
        )
    }
}

