import React from 'react';
import "./EntityPicker.scss";
import {IDBQuery} from "../../QueryBuilder/QueryBuilder";
import EntityBrowser from "../EntityBrowser/EntityBrowser";
import LoadSpinner from "../../LoadSpinner/LoadSpinner";
import ArrayManager from "../../ArrayManager/ArrayManager";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPencil} from "@fortawesome/free-solid-svg-icons";
import Popup from "../../Popup/Popup";

import {E_ENTITIES} from "../../../services/dataManagement/entityDefinitions";
import server from "../../../services/server";
import {ENTITIES} from "../../../services/dataManagement/entities";


interface IProps {
    entity: E_ENTITIES
    embedInPopup?: boolean,
    onRowClick?: (id:number)=>void,
    onSelect: (values:number[])=>void,
    selected: number[],
    limit?: number,
    readonly ?: boolean,
    disabled ?: boolean,
    selectPlaceholder?: string,
    currPage?: number,
    searchTerm?: string,
    query?: IDBQuery,
    onQueryChange?: (query:IDBQuery)=>void,
    onPageChange?: (p:number)=>void,
    onSearchTermChange?: (s:string)=>void,
    hideQueryEditor?: boolean,
    hideFreeSearch?: boolean,
}
interface IState {
    valueLabels: {[id:number]: string},
    loadingLabels: boolean,
    embedInPopup?: boolean,
    popupVisibility: boolean,
    currPage: number,
    searchTerm: string,
    query?: IDBQuery,
}

export default (class MultiEntityPicker extends React.Component <IProps, IState>{
    constructor(props:IProps) {
        super(props);
        this.state = {
            valueLabels: {},
            loadingLabels: true,
            embedInPopup: props.embedInPopup,
            popupVisibility: false,
            currPage: props.currPage ? props.currPage : 0,
            searchTerm: props.searchTerm ? props.searchTerm : "",
            query: props.query,
        };
    }

    componentDidMount() {
        return this.getLabels(this.props.selected);
    }

    componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        //return this.getLabels(this.props.selected);
    }

    onRowClick = async(id: number) => {
        if(this.props.readonly || this.props.disabled){
            return;
        }
        const values = this.props.selected ? this.props.selected.slice() : [];
        if(values.includes(id)){
            values.splice(values.indexOf(id), 1);
        }
        else{
            values.unshift(id)
        }
        if(this.props.limit && values.length > this.props.limit){
            values.splice(0, values.length - this.props.limit)
        }
        await this.onChange(values)
        if(this.props.onRowClick){
            await this.props.onRowClick(id);
        }
    };

    onChange = async(values: number[])=>{
        if(this.props.readonly || this.props.disabled){
            return;
        }
        this.props.onSelect(values)
        await this.getLabels(values);
    }

    getLabels = async(values:number[])=>{
        this.setState({loadingLabels: true}, async()=>{
            if(!values.length){
                this.setState({valueLabels: [], loadingLabels: false})
                return;
            }
            const entity = ENTITIES[this.props.entity];
            const labels:{id:number, label: string}[] = await server.post("./"+entity.endpoint+"/labels/",values)
            const valueLabels:{[key:number]: string} = Object.fromEntries(labels.map(x=>[x.id, x.label]))
            this.setState({valueLabels, loadingLabels: false})
        })
    }

    onQueryChange = (query:IDBQuery) =>{
        this.setState({query, currPage: 0}, ()=>{
            if(this.props.onQueryChange){
                this.props.onQueryChange(query)
            }
        })
    };


    switchPage = async(p:number)=>{
        this.setState({currPage: p}, ()=>{
            if(this.props.onPageChange){
                this.props.onPageChange(p)
            }
        })
    };

    setSearchTerm = async(s:string)=>{
        this.setState({searchTerm: s, currPage: 0}, ()=>{
            if(this.props.onSearchTermChange){
                this.props.onSearchTermChange(s)
            }
        })
    };

    render() {
        const picker = <EntityBrowser
            entity={this.props.entity}
            currPage={this.state.currPage}
            searchTerm={this.state.searchTerm}
            query={this.state.query}
            onSearchTermChange={(s)=>{return this.setSearchTerm(s)}}
            onPageChange={(p)=>{return this.switchPage(p)}}
            onQueryChange={(q)=>{return this.onQueryChange(q)}}
            onRowClick={(id)=>{return this.onRowClick(id)}}
            hideExport={true}
            hideQueryEditor={this.props.hideQueryEditor}
            hideFreeSearch={this.props.hideFreeSearch}
            selected={this.props.selected}
        />;
        const values = this.props.selected ? this.props.selected.slice() : [];
        const selectedValues = <ArrayManager
            values={
                values
                    .filter(x=>x!==undefined&&x!==null)
                    .map(x=>""+x)
            }
            labelDict={this.state.valueLabels}
            onChange={(values:number[])=>{this.onChange(values)}}
            readonly={false}
            disabled={false}
            disableAdd={true}
            disableEdit={true}
        />;
        return (
            <div className={"entity-picker-wrap"}>
            <span className="table-picker-values-wrap">
            {
                this.state.loadingLabels ?
                    <LoadSpinner visibility={true} radius={2}/>
                    : <React.Fragment>
                        {selectedValues}
                    </React.Fragment>
            }
            {
                this.state.embedInPopup && !this.props.readonly ?
                    <button type={"button"} disabled={this.props.disabled} onClick={()=>{this.setState({popupVisibility: true})}}>
                        {this.props.selectPlaceholder ? this.props.selectPlaceholder : <FontAwesomeIcon icon={faPencil}/>}
                    </button>
                    : null
            }
            </span>
                {this.state.embedInPopup ?
                    <Popup visibility={this.state.popupVisibility}
                           toggleVisibility={()=>{
                               this.setState({popupVisibility: !this.state.popupVisibility})
                           }} width={"80vw"}>
                        {selectedValues}
                        {picker}
                        {this.props.readonly ? null :
                            <button type={"button"} disabled={this.props.disabled} onClick={()=>{
                                this.setState({popupVisibility: !this.state.popupVisibility})
                            }} className={"big-button"}>
                                אישור
                            </button>
                        }
                    </Popup>
                    :
                    picker
                }
            </div>
        )
    }
}
)