import {fieldType, IField} from "./fields";
import {inputType} from "./fieldControls";
import {IDBQuery} from "../../UIComponents/QueryBuilder/QueryBuilder";
import {E_ENTITIES} from "./entityDefinitions";

export const CARD_INPUTS:{[key in fieldType]: inputType} = {
    [fieldType.NONE]: inputType.NONE,
    [fieldType.textType]: inputType.textInput,
    [fieldType.textareaType]: inputType.textareaInput,
    [fieldType.booleanType]: inputType.booleanInput,
    [fieldType.stringArray]: inputType.arrayManager,
    [fieldType.dateType]: inputType.dateInput,
    [fieldType.multipleOptionsType]: inputType.multiSelect,
    [fieldType.dropdown]: inputType.dropdown,
    [fieldType.jsonSchema]: inputType.jsonSchemaEditor,
    [fieldType.contactsQueryType]: inputType.contactsQueryEditor,
    [fieldType.contactCustomFields]: inputType.contactCustomFieldsEditor,
    [fieldType.activityCustomFields]: inputType.activityCustomFieldsEditor,
    [fieldType.entityType]: inputType.entityPicker,
    [fieldType.entitiesArrayType]: inputType.entitiesPicker,
    [fieldType.coordinatesType]: inputType.coordinatePicker,
    [fieldType.imageUploaderType]: inputType.imageUploader,
}

export interface ICardSectionFields {
    field: IField,
    readonly?: boolean,
    disabled?: boolean,
    hideOnNew?: boolean,
    classNames?: { [key:string]: boolean}
}

export interface ICard {
    sections: {
        type?: string,
        title?: string,
        hideOnNew?: boolean,
        compact?: boolean,
        fields?: ICardSectionFields[],
        data?: any
    }[],
    slots?: { [index: string]: ((state: IState, setValue: (v: any) => void) => JSX.Element)},
    relatedEntities?: {
        title: string,
        entity: E_ENTITIES,
        query: (id:number, data:any)=>IDBQuery
    }[]
}

export interface IState {
    loading: boolean,
    data: null | any,
    errors: string[],
    valid: boolean,
    saveLoading: boolean,
    saveSuccessful: boolean,
    saveFailed: boolean,
    saveErrors: string[] | null,
    lastUpdated: number,
    // fieldRefs: {[key:string]: RefObject<any>}
}